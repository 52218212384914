import { createApp } from 'vue';
import { createPinia } from 'pinia';
import axios from 'axios';

const nonPropAttributes = [
    'data-vue-component',
    'data-vue-use-axios',
    'data-vue-use-pinia',
];

const vueMountedComponents = {};

export function mountComponent(element, component) {
    if (vueMountedComponents.hasOwnProperty(element.getAttribute('data-vue-component')))
        return;

    const props = Array.from(element.attributes)
        .filter((attribute) => {
            if(!attribute.name.startsWith('data-'))
                return false;

            if(nonPropAttributes.includes(attribute.name))
                return false;

            return true;
        })
        .reduce((props, attribute) => {
            const prop = attribute.name.substring(5).replace(/-./g, (match) => match.charAt(1).toUpperCase());
            props[prop] = attribute.value;

            return props;
        }, {});

    const componentApp = createApp(component, props);
    if(element.hasAttribute('data-vue-use-axios') && element.getAttribute('data-vue-use-axios') !== 'false')
        componentApp.config.globalProperties.$axios = axios;

    if(element.hasAttribute('data-vue-use-pinia') && element.getAttribute('data-vue-use-pinia') !== 'false') {
        const pinia = createPinia();
        componentApp.use(pinia);
    }

    componentApp.mount(element);
    vueMountedComponents[element.getAttribute('data-vue-component')] = componentApp;
}

const processAndObserve = (node, observer, observedComponents) => {
    const observedComponentNames = Object.keys(observedComponents);
    if (node.nodeType === Node.ELEMENT_NODE) {
        if(node.hasAttribute('data-vue-component') && observedComponentNames.includes(node.getAttribute('data-vue-component')))
            mountComponent(node, observedComponents[node.getAttribute('data-vue-component')]);

        observer.observe(node, { childList: true, subtree: true });
        node.querySelectorAll('[data-vue-component]').forEach((element) => {
            if (element.hasAttribute('data-vue-component') && observedComponentNames.includes(element.getAttribute('data-vue-component')))
                mountComponent(element, observedComponents[element.getAttribute('data-vue-component')]);
        });
    }
};

export function startObserver(observedComponents) {
    const observedComponentNames = Object.keys(observedComponents);
    const observer = new MutationObserver((mutationsList) => {
        mutationsList.forEach((mutation) => {
            mutation.addedNodes.forEach((node) => {
                if (node.nodeType !== Node.ELEMENT_NODE)
                    return;

                processAndObserve(node, observer, observedComponents);
            });

            mutation.removedNodes.forEach((node) => {
                if(node.nodeType !== Node.ELEMENT_NODE)
                    return;

                if(node.hasAttribute('data-vue-component') && vueMountedComponents.hasOwnProperty(node.getAttribute('data-vue-component'))) {
                    const componentApp = vueMountedComponents[node.getAttribute('data-vue-component')];
                    componentApp.unmount();
                    delete vueMountedComponents[node.getAttribute('data-vue-component')];
                }
            });
        });
    });

    observer.observe(document.body, { childList: true, subtree: true });
    document.querySelectorAll('[data-vue-component]').forEach((element) => {
        if (element.hasAttribute('data-vue-component') && observedComponentNames.includes(element.getAttribute('data-vue-component')))
            mountComponent(element, observedComponents[element.getAttribute('data-vue-component')]);
    });
}
